import { useIntl } from 'react-intl';
import ScrollTopOnMount from 'utils/components/ScrollTopOnMount';
import ContactForSamplesForm from 'modules/contact/ContactForSamplesPage/components/ContactForSamplesForm';
import CallUs from 'modules/contact/ContactUsPage/components/CallUs';
import heroBg from 'modules/contact/ContactUsPage/img/hero-background.jpg';
import 'modules/contact/ContactUsPage/styles/ContactUsPage.scss';
import "modules/contact/ContactUsPage/styles/ContactUsPage.scss";
import SEO from "services/seo/components/SEO";
import { CONTACT_FOR_SAMPLES_SCENE } from "services/seo/constants/scenes";

const ContactForSamplesPage = () => {
  const intl = useIntl();
  const { locale } = intl;
  
  return (
    <div className='contact-us-page'>
      <ScrollTopOnMount />
      <div
        className='contact-page-hero'
        style={{
          backgroundImage: `url(${heroBg})`,
        }}
      />
      <div className='contact-us-page__contact-box'>
        <div className='container mb-5'>
          <ContactForSamplesForm idForGTM={locale === 'es' ? 'solicitud-de-catalogo' : 'solicitud-de-catalogo'} />
        </div>
      </div>
      <CallUs/>

    <SEO scene={CONTACT_FOR_SAMPLES_SCENE} />
    </div>
  );
};
export default ContactForSamplesPage;
