import { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useIntl } from 'react-intl';
import singlePatternApprovedCleanersQuery from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadApprovedCleanersButton/query";
import { FormattedMessage } from "react-intl";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import { createDownloadLog } from "modules/products/ProductDetailsPage/components/ProductSidebar/mutations";
import DownloadProtectModal from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadProtectModal";
import { useSelector } from "react-redux";
import meQuery from "utils/queries/MeQuery";

const DownloadApprovedCleanersButton = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const { data: userData } = useQuery(meQuery);
  const pattern = useContext(PatternContext);
  const [isOpen, setIsOpen] = useState(false);
  const [logDownload] = useMutation(createDownloadLog, {
    onError(error) {
      console.warn(error);
    },
  });
  const { loading, error, data } = useQuery(
    singlePatternApprovedCleanersQuery,
    { variables: { id: pattern.id } }
  );
  
  const intl = useIntl();
  const { locale } = intl;

  function handleClick() {
    logDownload({
      variables: {
        input: {
          email: userData && userData.me.email,
          patternId: pattern.id,
          resourceType: "approved-cleaners",
          resourceUrl: data.pattern.approvedCleanersPdfUrl,
        },
      },
    });
  }

  if (loading || error) return null;

  return (
    <>
      {isAuthenticated ? (
        <a
          className="btn btn-outline-dark btn-lg btn-block"
          href={data.pattern.approvedCleanersPdfUrl}
          rel="noopener noreferrer"
          target="_blank"
          onClick={handleClick}
        >
          <FormattedMessage
            id="DownloadApprovedCleanersButton.label"
            description="Product details download approved cleaners button label"
            defaultMessage="Descargar limpiadores aprobados"
          />
          <i className="fa fa-file-pdf-o" />
        </a>
      ) : (
        <button
          className="btn btn-outline-dark btn-lg btn-block"
          onClick={() => setIsOpen(true)}
        >
          <FormattedMessage
            id="DownloadApprovedCleanersButton.label"
            description="Product details download approved cleaners button label"
            defaultMessage="Descargar limpiadores aprobados"
          />
          <i className="fa fa-file-pdf-o" />
        </button>
      )}
      <DownloadProtectModal
        idForGTM={locale === 'es' ? 'descargar-limpiadores-aprobados' : 'print-approved-cleaners'}
        title={
          <FormattedMessage
            id="DownloadApprovedCleanersButton.downloadModalTitle"
            defaultMessage="Descargar limpiadores aprobados"
          />
        }
        subtitle={
          <FormattedMessage
            id="DownloadApprovedCleanersButton.downloadModalSubtitle"
            defaultMessage="Queremos mantenernos en contacto. Déjanos tus datos y descarga los limpiadores aprovados de ésta referencia."
          />
        }
        id="download-approved-cleaners-form"
        isOpen={isOpen}
        patternId={pattern.id}
        resourceType="approved-cleaners"
        resourceUrl={data.pattern.approvedCleanersPdfUrl}
        toggle={() => setIsOpen(!isOpen)}
      />
    </>
  );
};

export default DownloadApprovedCleanersButton;
