import { Link } from "react-router-dom";
import { PRODUCTS_LIBRARY } from "routes";
import { getTranslatedLink } from "services/instances";
import "./not-found-page.scss";
import { FormattedMessage } from "react-intl";
import SEO from "services/seo/components/SEO";
import { NOT_FOUND_SCENE } from "services/seo/constants/scenes";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="my-3 text-center">
        <FormattedMessage
          id="NotFoundPage.title"
          description="Label when product not exist or valid"
          defaultMessage="Article not found"
        />
      </h1>
      <hr />
      <Link to={getTranslatedLink(PRODUCTS_LIBRARY, {})}>
        <p className="not-found-link my-3 text-center">
          <FormattedMessage
            id="NotFoundPage.link"
            description="Label to go to the product list"
            defaultMessage="Return to product list of spradling"
          />
        </p>
      </Link>

    <SEO scene={NOT_FOUND_SCENE} />
    </div>
  );
};

export default NotFoundPage;
