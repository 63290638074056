import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { graphql } from "react-apollo";
import searchQuery from "modules/search/SearchResultsPage/query";
import Skeleton from "react-loading-skeleton";
import NoResultsMessage from "utils/components/NoResultsMessage";
import "./styles/SearchResultsPage.scss";
import RESULT_COMPONENTS from "./components/ResultComponents";
import SEO from "services/seo/components/SEO";
import { SEARCH_RESULT_SCENE } from "services/seo/constants/scenes";

export const SearchResultsPage = ({
  searchQuery: { loading, search },
  match,
}) => {
  return (
    <div className="search-results-page">
      <div className="container pt-4 pb-4 pt-lg-5 pb-lg-5">
        <div className="row">
          <div className="col-6">
            <h1>
              <FormattedMessage
                id="SearchResultsPage.title"
                description="Title of Search results page"
                defaultMessage="Resultados de búsqueda"
              />
            </h1>
          </div>
          <div className="col-6 text-right">
            <div className="mt-4">
              {!loading ? (
                <FormattedMessage
                  id="SearchResultsPage.resultCount"
                  description="Number of results found on the search page"
                  defaultMessage={`<strong>{results_count}</strong> resultados para "<strong>{text}</strong>"`}
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    results_count: search.length,
                    text: match.params.text,
                  }}
                />
              ) : (
                <Skeleton width="50%" />
              )}
            </div>
          </div>
        </div>
        <div className="search-results-page__results">
          {loading &&
            [1, 2, 3, 4, 5].map((item) => (
              <div key={item} className="search-result__result">
                <Skeleton height="60px" />
              </div>
            ))}
          {!loading && search.length === 0 && <NoResultsMessage />}
          {!loading &&
            search.map((result) => {
              const ResultComponent = RESULT_COMPONENTS[result.item_type];
              return (
                ResultComponent && (
                  <ResultComponent key={`key_${result.slug}`} result={result} />
                )
              );
            })}
        </div>
      </div>

    <SEO scene={SEARCH_RESULT_SCENE} />
    </div>
  );
};

SearchResultsPage.propTypes = {
  searchQuery: PropTypes.shape({
    loading: PropTypes.bool,
    search: PropTypes.object,
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

// TODO: update query using hooks
export default graphql(searchQuery, {
  name: "searchQuery",
  options: (props) => ({
    variables: {
      text: props.match.params.text,
      language: props.match.params.language,
      instance: props.match.params.instance,
    },
  }),
})(SearchResultsPage);
