import { defineMessages } from "react-intl";
import {
  HOME_SCENE,
  MARKET_CONTRACT_ES_SCENE,
  MARKET_MARINA_ES_SCENE,
  MARKET_RESIDENTIAL_ES_SCENE,
  MARKET_TRANSPORT_ES_SCENE,
  MARKET_FOOTWARE_AND_LEATHER_GOODS_ES_SCENE,
  MARKET_PROTECTION_AND_APPAREL_ES_SCENE,
  MARKET_FLOORING_ES_SCENE,
  MARKET_CONTRACT_EN_SCENE,
  MARKET_RESIDENTIAL_EN_SCENE,
  MARKET_MARINA_EN_SCENE,
  MARKET_TRANSPORT_EN_SCENE,
  MARKET_FOOTWARE_AND_LEATHER_GOODS_EN_SCENE,
  MARKET_PROTECTION_AND_APPAREL_EN_SCENE,
  MARKET_FLOORING_EN_SCENE,
  SECTOR_HEALTHCARE_EN_SCENE,
  SECTOR_HOSPITALITY_EN_SCENE,
  SECTOR_RESTAURANT_EN_SCENE,
  SECTOR_OFFICE_EN_SCENE,
  SECTOR_EDUCATION_EN_SCENE,
  SECTOR_PUBLIC_SPACES_EN_SCENE,
  SECTOR_EXTERIORS_EN_SCENE,
  SECTOR_INTERIORS_EN_SCENE,
  SECTOR_MARINE_EXTERIORS_EN_SCENE,
  SECTOR_MARINE_INTERIORS_EN_SCENE,
  SECTOR_MARINE_PERSONAL_WATER_CRAFT_EN_SCENE,
  SECTOR_GENERAL_AUTOMOTIVE_EN_SCENE,
  SECTOR_MASS_TRANSPORTATION_EN_SCENE,
  SECTOR_MOTORCYCLES_EN_SCENE,
  SECTOR_RECREATIONAL_VEHICLES_EN_SCENE,
  SECTOR_ATV_EN_SCENE,
  SECTOR_FOOTWARE_EN_SCENE,
  SECTOR_LEATHER_GOODS_EN_SCENE,
  SECTOR_COVERS_EN_SCENE,
  SECTOR_AWNINGS_EN_SCENE,
  SECTOR_CLOTHING_EN_SCENE,
  SECTOR_RESIDENTIAL_EN_SCENE,
  SECTOR_COMMERCIAL_EN_SCENE,
  SECTOR_INDUSTRIAL_EN_SCENE,
  SECTOR_HEALTHCARE_ES_SCENE,
  SECTOR_HOSPITALITY_ES_SCENE,
  SECTOR_RESTAURANT_ES_SCENE,
  SECTOR_OFFICE_ES_SCENE,
  SECTOR_EDUCATION_ES_SCENE,
  SECTOR_PUBLIC_SPACES_ES_SCENE,
  SECTOR_EXTERIORS_ES_SCENE,
  SECTOR_INTERIORS_ES_SCENE,
  SECTOR_MARINE_EXTERIORS_ES_SCENE,
  SECTOR_MARINE_INTERIORS_ES_SCENE,
  SECTOR_MARINE_PERSONAL_WATER_CRAFT_ES_SCENE,
  SECTOR_GENERAL_AUTOMOTIVE_ES_SCENE,
  SECTOR_MASS_TRANSPORTATION_ES_SCENE,
  SECTOR_MOTORCYCLES_ES_SCENE,
  SECTOR_RECREATIONAL_VEHICLES_ES_SCENE,
  SECTOR_ATV_ES_SCENE,
  SECTOR_FOOTWARE_ES_SCENE,
  SECTOR_LEATHER_GOODS_ES_SCENE,
  SECTOR_COVERS_ES_SCENE,
  SECTOR_AWNINGS_ES_SCENE,
  SECTOR_CLOTHING_ES_SCENE,
  SECTOR_RESIDENTIAL_ES_SCENE,
  SECTOR_COMMERCIAL_ES_SCENE,
  SECTOR_INDUSTRIAL_ES_SCENE,
  PRODUCTS_LIST_SCENE,
  WHERE_TO_BUY_SCENE,
  CONTACT_PQR_SCENE,
  CONTACT_SCENE,
  BRANDS_SCENE,
  ECOSENSE_SCENE,
  WE_ARE_SPRADLING_SCENE,
  SPRADLING_IN_THE_WORLD_SCENE,
  WORK_WITH_US_SCENE,
  PROJECTS_SCENE,
  DOCUMENT_LIBRARY_SCENE,
  WHY_CHOOSE_US_SCENE,
  DOCUMENT_LIBRARY_IN_MENU,
  BLOG_LIST_SCENE,
  SEARCH_RESULT_SCENE,
  CART_SCENE,
  CART_SUCCESS_SCENE,
  NOT_FOUND_SCENE
} from "services/seo/constants/scenes";

// header data to MARINE instance
export const HEADERS_DATA_MARINE = [
  {
    scene: HOME_SCENE,
    title: defineMessages({
      [HOME_SCENE]: {
        id: `HeaderData.homeSceneTitle`,
        description: "Page title to Home scene",
        defaultMessage: "Grupo Spradling - Inicio",
      },
    })[HOME_SCENE],
    description: defineMessages({
      [HOME_SCENE]: {
        id: `HeaderData.homeSceneDescription`,
        description: "Page description to Home scene",
        defaultMessage: "Grupo Spradling - Portal Global",
      },
    })[HOME_SCENE],
    keywords: "spradling",
    translated: true,
  },
  {
    scene: PRODUCTS_LIST_SCENE,
    title: defineMessages({
      [HOME_SCENE]: {
        id: `HeaderData.productsListTitle`,
        description: "Page title for Products List scene",
        defaultMessage:
          "Conoce características y composición de nuestros productos",
      },
    })[HOME_SCENE],
    description: defineMessages({
      [HOME_SCENE]: {
        id: `HeaderData.productsListDescription`,
        description: "Page description for Products List scene",
        defaultMessage:
          "Conoce nuestro catálogo de productos, en donde podrás encontrar una gran variedad de colores, texturas y tecnologías que puedes aplicar en todo tipo de espacios.",
      },
    })[HOME_SCENE],
    keywords: "spradling",
    translated: true,
  },
  {
    scene: MARKET_CONTRACT_ES_SCENE,
    title: "Las mejores telas recubiertas para hospitales, encuéntralas aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para hospitales. Tenemos telas recubiertas antimicrobianas y mucho más.",
    keywords: "telas recubiertas para interiores",
    translated: false,
  },
  {
    scene: MARKET_CONTRACT_EN_SCENE,
    title: "Check out the best upholstery fabrics in the market",
    description:
      "Here you will find our full range of upholstery fabrics. Our portfolio includes fabrics designed for your office and more. Find out more here!",
    keywords: "upholstery fabrics",
    translated: false,
  },
  {
    scene: MARKET_RESIDENTIAL_ES_SCENE,
    title: "Las mejores telas recubiertas para el hogar, encuéntralas aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas especializadas para el hogar. Haz de tu casa el espacio que soñaste con materiales premium.",
    keywords: "telas recubiertas para el hogar",
    translated: false,
  },
  {
    scene: MARKET_RESIDENTIAL_EN_SCENE,
    title: "Check out the best interior design fabrics out there",
    description:
      "Here you will find our interior design fabrics portfolio.  Create your dream home with materials that last, are water resistant and easy to maintain",
    keywords: "Interior design fabrics",
    translated: false,
  },
  {
    scene: MARKET_MARINA_ES_SCENE,
    title: "Tapicería marina de alto rendimiento",
    description:
      "Aquí encontrarás el portafolio de nuestra telas recubiertas diseñadas para resistir las duras condiciones que deben afrontar las embarcaciones",
    keywords: "tapiceria marina",
    translated: false,
  },
  {
    scene: MARKET_MARINA_EN_SCENE,
    title: "Check out our marine upholstery portfolio",
    description:
      "Here you will find the best marine fabrics. We work to develop well designed and high great performance marine collections. Find out more here!",
    keywords: "marine upholstery",
    translated: false,
  },
  {
    scene: MARKET_TRANSPORT_ES_SCENE,
    title: "Tapicería de calidad para automóviles, encuéntrala aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para tapizar automóviles. Desarrollamos tapicería y pisos para todo tipo de autos.",
    keywords:
      "tapiceria para automoviles, Tapiceria para carros, tapiceria coche, - España, tapiceria automotriz - México",
    translated: false,
  },
  {
    scene: MARKET_TRANSPORT_EN_SCENE,
    title: "Check out our automotive upholstery portfolio",
    description:
      "Here you will find the best upholstery and floors for cars, vans, school buses and more. Our fabrics are approved to be used as original equipment ",
    keywords: "automotive upholstery",
    translated: false,
  },
  {
    scene: MARKET_FOOTWARE_AND_LEATHER_GOODS_ES_SCENE,
    title: "Telas recubiertas para marroquineria y zapatos",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para marroquinería y zapatos - Contamos con una amplia gama de características.",
    keywords: "telas recubiertas para marroquineria y zapatos",
    translated: false,
  },
  {
    scene: MARKET_FOOTWARE_AND_LEATHER_GOODS_EN_SCENE,
    title: "Check out our footwear fabrics portfolio",
    description:
      "Here you will find the best fabrics for any footwear brands. Our DESIGNS, colors, and finishes are inspired by the latest trends. Find out more here!",
    keywords: "footwear fabrics",
    translated: false,
  },
  {
    scene: MARKET_PROTECTION_AND_APPAREL_ES_SCENE,
    title: "Telas recubiertas para vestuario y dotación de alta calidad",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para vestuario y dotación - Nuestros tejidos se adaptan a las necesidades de protección.",
    keywords: "telas recubiertas para vestuario y dotacion",
    translated: false,
  },
  {
    scene: MARKET_PROTECTION_AND_APPAREL_EN_SCENE,
    title: "Check out our clothing and protection fabrics",
    description:
      "Here you will find our clothing fabrics portfolio. We create coated fabrics that adapt to all types of clothes and protecting garments. Find out more here!",
    keywords: "clothing and protection fabrics",
    translated: false,
  },
  {
    scene: MARKET_FLOORING_ES_SCENE,
    title: "Pisos de alta calidad residenciales, comerciales e industriales",
    description:
      "Aquí encontrarás el portafolio de nuestros pisos residenciales, comerciales e industriales - Los mejores pisos de alta duración en el mercado.",
    keywords: "pisos de alta calidad",
    translated: false,
  },
  {
    scene: MARKET_FLOORING_EN_SCENE,
    title: "Check out our vinyl flooring portfolio right here",
    description:
      "Here you will find all our vinyl flooring portfolio to create unique and warm experiences. We have residential, commercial and industrial options.",
    keywords: "vinyl floorinG",
    translated: false,
  },
  {
    scene: SECTOR_HEALTHCARE_EN_SCENE,
    title: "Check out the best healthcare fabrics in the market",
    description:
      "Here you will find our healthcare fabrics portfolio. We have developed new technologies to create stain and abrasion resistant fabrics. Find out more here!",
    keywords: "healthcare fabrics",
    translated: false,
  },
  {
    scene: SECTOR_HOSPITALITY_EN_SCENE,
    title: "Check out the best furniture fabrics in the market",
    description:
      "Here you will find our furniture fabrics portfolio. Our coated fabrics solve innumerable seating challenges in hospitality environments. Find out more here!",
    keywords: "furniture fabrics",
    translated: false,
  },
  {
    scene: SECTOR_RESTAURANT_EN_SCENE,
    title: "Check out the best restaurant fabrics in the market",
    description:
      "Here you will find our restaurant fabrics portfolio. Our coated fabrics solve innumerable challenges with both quality and design. Find out more here!",
    keywords: "restaurant fabrics",
    translated: false,
  },
  {
    scene: SECTOR_OFFICE_EN_SCENE,
    title: "Office fabrics for world class workspaces",
    description:
      "Here you will find our office fabrics portfolio. Our fabrics are durable, stylish, colorful, easy to clean, and versatile. Find out more here!",
    keywords: "Office fabrics",
    translated: false,
  },
  {
    scene: SECTOR_EDUCATION_EN_SCENE,
    title: "The best fabrics for educational spaces",
    description:
      "Here you will find our fabrics for educational spaces. Our fabrics are durable, stylish, colorful, easy to clean and versatile. Find out more here!",
    keywords: "fabrics for educational spaces",
    translated: false,
  },
  {
    scene: SECTOR_PUBLIC_SPACES_EN_SCENE,
    title: "Check out our high performance fabrics",
    description:
      "Here you will find the best fabrics for public spaces that require an outstanding performance under any external conditions. Find out more here!",
    keywords: "high perfomance fabrics",
    translated: false,
  },
  {
    scene: SECTOR_EXTERIORS_EN_SCENE,
    title: "Check out the best residential outdoor fabrics",
    description:
      "Here you will find our outdoor fabrics portfolio.  The most durable coated fabrics are those that last, are water resistant and easy to maintain",
    keywords: "Residential outdoor fabrics",
    translated: false,
  },
  {
    scene: SECTOR_INTERIORS_EN_SCENE,
    title: "Check out the best interior upholstery fabrics",
    description:
      "Here you will find our residential upholstery fabrics.  The most durable coated fabrics for living rooms, bedrooms, dining rooms, and much more",
    keywords: "interior upholstery fabrics",
    translated: false,
  },
  {
    scene: SECTOR_MARINE_EXTERIORS_EN_SCENE,
    title: "Check out our outdoor maring fabrics",
    description:
      "Here you will find the best outdoor marine fabrics. Our coated fabrics are engineered, tested and certified to resist the toughest conditions at sea",
    keywords: "outdoor marine fabrics",
    translated: false,
  },
  {
    scene: SECTOR_MARINE_INTERIORS_EN_SCENE,
    title: "Check out our marine interior fabrics",
    description:
      "Here you will find the best marine interior fabrics. A wide portfolio of coated fabrics for comfortable spaces. Ideal for upholstery, walls, and ceilings",
    keywords: "marine interior fabrics",
    translated: false,
  },
  {
    scene: SECTOR_MARINE_PERSONAL_WATER_CRAFT_EN_SCENE,
    title: "Check out our water sport fabrics",
    description:
      "Here you will find the best water sport fabrics. A wide portfolio of water and sun resistant fabrics with antislip technology for a better experience.",
    keywords: "water sport fabrics",
    translated: false,
  },
  {
    scene: SECTOR_GENERAL_AUTOMOTIVE_EN_SCENE,
    title: "Check out our car upholstery portfolio",
    description:
      "Here you will find the best upholstery fabrics for any car.  Our materials can be used for upholstery, doors, ceilings, and floors. Find out more here!",
    keywords: "car upholstery",
    translated: false,
  },
  {
    scene: SECTOR_MASS_TRANSPORTATION_EN_SCENE,
    title: "Check out our public transport upholstery here",
    description:
      "Our innovative coated fabrics are suitable for high-traffic seating upholstery and interiors for all sorts of public transport vehicles. Find out more here!",
    keywords: "public transportation upholstery",
    translated: false,
  },
  {
    scene: SECTOR_MOTORCYCLES_EN_SCENE,
    title: "Check out our motorcycle upholstery portfolio",
    description:
      "Here you will find the best upholstery fabrics for any motorcycle. Our coated fabrics are water and UV resistant, flexible and durable. Find out more here!",
    keywords: "motorcycle upholstery",
    translated: false,
  },
  {
    scene: SECTOR_RECREATIONAL_VEHICLES_EN_SCENE,
    title: "Check out our caravan and RV upholstery here",
    description:
      "Here you will find the best upholstery fabrics for your caravan. We have a broad portfolio of colors, textures, and DESIGNS. Find out more here!",
    keywords: "upholstery fabrics for rvs",
    translated: false,
  },
  {
    scene: SECTOR_ATV_EN_SCENE,
    title: "",
    description: "",
    keywords: "",
    translated: false,
  },
  {
    scene: SECTOR_FOOTWARE_EN_SCENE,
    title: "Check out our shoes fabrics portfolio here",
    description:
      "Here you will find the best fabrics for all types of shoes you can imagine. Our coated fabrics are water resistant, easy to clean, durable and flexible",
    keywords: "shoes fabrics",
    translated: false,
  },
  {
    scene: SECTOR_LEATHER_GOODS_EN_SCENE,
    title: "Check out our leather fabrics portfolio here",
    description:
      "Here you will find the our leather-goods fabrics portfolio. We have a wide variety of textures, DESIGNS and colors to make all your DESIGNS come true. ",
    keywords: "leather fabrics",
    translated: false,
  },
  {
    scene: SECTOR_COVERS_EN_SCENE,
    title: "Check out our protection fabrics portfolio",
    description:
      "Here you will find all our lightweight materials to cover and protect equipment, appliances, spaces and more. Click here for our full portfolio!",
    keywords: "Protection fabrics",
    translated: false,
  },
  {
    scene: SECTOR_AWNINGS_EN_SCENE,
    title: "Check out our awning fabrics for all types of places",
    description:
      "Here you will find our awning fabrics portfolio. We create high end fabrics that adapt to all types of places and venues. Find out more here!",
    keywords: "awning fabrics",
    translated: false,
  },
  {
    scene: SECTOR_CLOTHING_EN_SCENE,
    title: "Check out our clothing fabrics portfolio here",
    description:
      "Here you will find our clothing and garments fabrics portfolio. Our fabrics protect our clients from rain, stains, bacteria and/or chemical agents.",
    keywords: "clothing fabrics",
    translated: false,
  },
  {
    scene: SECTOR_RESIDENTIAL_EN_SCENE,
    title: "Check out our residential vinyl flooring portfolio",
    description:
      "Here you will find all our high quality vinyl flooring portfolio to create unique and warm experiences for all your residential needs.",
    keywords: "residential vinyl floring",
    translated: false,
  },
  {
    scene: SECTOR_COMMERCIAL_EN_SCENE,
    title: "Check out our commercial vinyl flooring portfolio",
    description:
      "Here you will find all our high quality commercial vinyl flooring portfolio to create unique and warm experiences for all your needs.",
    keywords: "commercial vinyl flooring",
    translated: false,
  },
  {
    scene: SECTOR_INDUSTRIAL_EN_SCENE,
    title: "Check out our industrial vinyl flooring portfolio",
    description:
      "Here you will find all our high quality industrial vinyl flooring portfolio to create unique and warm experiences for all your needs.",
    keywords: "industrial vinyl flooring",
    translated: false,
  },

  {
    scene: SECTOR_HEALTHCARE_ES_SCENE,
    title: "Las mejores telas recubiertas para hospitales, encuéntralas aquí ",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para hospitales. Tenemos telas recubiertas antimicrobianas y mucho más.",
    keywords: "telas recubiertas para hospitales",
    translated: false,
  },
  {
    scene: SECTOR_HOSPITALITY_ES_SCENE,
    title: "Telas recubiertas de uso hotelero, encuéntralas aquí ",
    description:
      "Aquí encontrarás todo el portafolio de nuestras telas recubiertas especializadas para interiores hoteleros. Tenemos todo lo que buscas.",
    keywords: "telas recubiertas para hoteleria",
    translated: false,
  },
  {
    scene: SECTOR_RESTAURANT_ES_SCENE,
    title: "Telas recubiertas para restaurantes, encuéntralas aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas especializadas para restaurantes. Nuestras telas vinílicas son durables y mucho más.",
    keywords: "telas recubiertas para restaurantes (longtail)",
    translated: false,
  },
  {
    scene: SECTOR_OFFICE_ES_SCENE,
    title: "Las mejores telas recubiertas para oficinas, encuéntralas aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas especializadas para el mobiliario de oficinas. Nuestras telas son de alta calidad y durables.",
    keywords: "telas recubiertas para oficinas",
    translated: false,
  },
  {
    scene: SECTOR_EDUCATION_ES_SCENE,
    title: "Las mejores telas recubiertas para colegios y universidades aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas especializadas para colegios y universidades. Nuestras telas recubiertas son muy durables.",
    keywords: "telas recubiertas para colegios y universidades",
    translated: false,
  },
  {
    scene: SECTOR_PUBLIC_SPACES_ES_SCENE,
    title: "Telas recubiertas de alto rendimiento para exteriores públicos",
    description:
      "Aquí encontrarás el portafolio de nuestra telas recubiertas de calidad y el alto rendimiento para los exteriores públicos más exigentes. ",
    keywords: "telas recubiertas para espacios publicos",
    translated: false,
  },
  {
    scene: SECTOR_EXTERIORS_ES_SCENE,
    title: "Las mejores telas recubiertas para los exteriores del hogar",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas especializadas para los exteriores de tu hogar. Haz de tu casa el espacio que soñaste",
    keywords: "telas recubiertas para exteriores del hogar",
    translated: false,
  },
  {
    scene: SECTOR_INTERIORS_ES_SCENE,
    title: "Las mejores telas recubiertas para los interiores del hogar",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas especializadas salas, comedores, habitaciones y muchos otros espacios de tu hogar.",
    keywords: "telas recubiertas para interiores del hogar",
    translated: false,
  },
  {
    scene: SECTOR_MARINE_EXTERIORS_ES_SCENE,
    title: "telas recubiertas para exteriores náuticos de alto rendimiento",
    description:
      "Aquí encontrarás el portafolio completo de nuestras telas recubiertas para exteriores de todo tipo de embarcaciones, tanto comerciales como de lujo",
    keywords: "telas recubiertas para exteriores nauticos",
    translated: false,
  },
  {
    scene: SECTOR_MARINE_INTERIORS_ES_SCENE,
    title: "telas recubiertas para interiores náuticos de alto rendimiento",
    description:
      "Aquí encontrarás el portafolio completo de nuestras telas recubiertas para interiores de todo tipo de embarcaciones, tanto comerciales como de lujo.",
    keywords: "telas recubiertas para interiores nauticos",
    translated: false,
  },
  {
    scene: SECTOR_MARINE_PERSONAL_WATER_CRAFT_ES_SCENE,
    title: "telas recubiertas para deportes acuáticos de alto rendimiento",
    description:
      "Aquí encontrarás el portafolio completo de nuestras telas recubiertas para deportes acuáticos. Nuestras telas recubiertas son durables y antideslizantes.",
    keywords: "telas recubiertas para deportes acuaticos",
    translated: false,
  },
  {
    scene: SECTOR_GENERAL_AUTOMOTIVE_ES_SCENE,
    title: "Tapicería de alta calidad para carros, encuéntralas acá",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para tapizar tu carro. Desarrollamos tapicería de muchos tipos y colores para tus necesidades.",
    keywords: "tapiceria para carros",
    translated: false,
  },
  {
    scene: SECTOR_MASS_TRANSPORTATION_ES_SCENE,
    title: "Tapicería para transporte público de alta calidad",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para transporte público. Nuestra tapicería es de alta calidad, y de retardancia al fuego.",
    keywords: "tapiceria para transporte publico",
    translated: false,
  },
  {
    scene: SECTOR_MOTORCYCLES_ES_SCENE,
    title: "Tapicería para asientos de motocicletas, encuéntralas aquí",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para tu moto. Nuestra tapicería es resistente al agua, flexible, y te protege contra rayos UV",
    keywords: "tapiceria para asientos de motocicletas",
    translated: false,
  },
  {
    scene: SECTOR_RECREATIONAL_VEHICLES_ES_SCENE,
    title: "Tapicería para todo tipo de vehículos recreacionales",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para tu vehículo recreacional. Nuestra tapicería resiste todo tipo de clima y es cómoda",
    keywords: "tapiceria para rvs",
    translated: false,
  },
  {
    scene: SECTOR_ATV_ES_SCENE,
    title: "Telas vinílicas para vehículos todo terreno y condiciones extremas",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para tu vehículos todo terreno. Nuestra tapicería resiste condiciones extremas",
    keywords: "telas vinílicas todo terreno",
    translated: false,
  },
  {
    scene: SECTOR_FOOTWARE_ES_SCENE,
    title: "Telas recubiertas para zapatería de alta calidad y desempeño",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para zapatos - Además de estar a la vanguardia, nuestros tejidos son impermeables.",
    keywords: "telas recubiertas para zapateria",
    translated: false,
  },
  {
    scene: SECTOR_LEATHER_GOODS_ES_SCENE,
    title: "Telas recubiertas para marroquineria de alta calidad y desempeño",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para marroquinería - Las mejores telas con apariencia a cuero, gamuza y charol.",
    keywords: "telas recubiertas para marroquineria",
    translated: false,
  },
  {
    scene: SECTOR_COVERS_ES_SCENE,
    title: "Telas para confección de forros de alta calidad y desempeño",
    description:
      "Aquí encontrarás el portafolio de nuestras telas para la confección de forros - Nuestras lineas de vestuario son impermeables, de color duradero y vivo",
    keywords: "telas para confección de forros",
    translated: false,
  },
  {
    scene: SECTOR_AWNINGS_ES_SCENE,
    title: "Telas recubiertas para cubiertas de equipos y espacios",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas y materiales livianos para cubrir y proteger equipos, electrodomésticos y espacios",
    keywords: "telas recubiertas para cubiertas de equipos y espacios",
    translated: false,
  },
  {
    scene: SECTOR_CLOTHING_ES_SCENE,
    title: "Telas recubiertas para vestuario de alta calidad y desempeño",
    description:
      "Aquí encontrarás el portafolio de nuestras telas recubiertas para vestuario - Nuestras lineas de vestuario son impermeables, de color duradero y vivo.",
    keywords: "telas recubiertas para vestuario",
    translated: false,
  },
  {
    scene: SECTOR_RESIDENTIAL_ES_SCENE,
    title: "Pisos de uso residencial, para todo tipo de hogares",
    description:
      "Aquí encontrarás el portafolio de nuestros pisos de uso residencial para todo tipo de vivienda - Los mejores pisos de alta duración en el mercado.",
    keywords: "pisos de uso residencial",
    translated: false,
  },
  {
    scene: SECTOR_COMMERCIAL_ES_SCENE,
    title: "Pisos de uso comercial para todo tipo de negocios",
    description:
      "Aquí encontrarás el portafolio de nuestros pisos de uso comercial para restaurantes, auditorios y todo tipo de negocio - Los mejores pisos de alta duración.",
    keywords: "pisos de uso comercial",
    translated: false,
  },
  {
    scene: SECTOR_INDUSTRIAL_ES_SCENE,
    title: "Pisos de uso industrial para todo tipo de negocios",
    description:
      "Aquí encontrarás el portafolio de nuestros pisos que cumplen con las exigencias técnicas que requieras, son duraderos, y soportan un tráfico elevado.",
    keywords: "pisos de uso industrial",
    translated: false,
  },
  {
    scene: WHERE_TO_BUY_SCENE,
    title: defineMessages({
      id: "HeaderData.whereToBuyTitle",
      defaultMessage: "Ubicación de nuestras tiendas"
    }),
    description: defineMessages({
      id: "HeaderData.whereToBuyDescription",
      defaultMessage: "¿Estás buscando un distribuidor? Aquí te ayudamos a encontrar el más cercano para que puedas adquirir nuestros productos."
    }),
    translated: true,
  },
  {
    scene: CONTACT_PQR_SCENE,
    title: defineMessages({
      id: "HeaderData.contactPqrTitle",
      defaultMessage: "Formulario de dudas, quejas y sugerencia"
    }),
    description: defineMessages({
      id: "HeaderData.contactPqrDescription",
      defaultMessage: "Compártenos tus dudas, sugerencias o quejas. Queremos estar en constante mejora de nuestro servicio para ti."
    }),
    translated: true,
  },
  {
    scene: CONTACT_SCENE,
    title: defineMessages({
      id: "HeaderData.contactUsTitle",
      defaultMessage: "Contacto con Spradling"
    }),
    description: defineMessages({
      id: "HeaderData.contactUsDescription",
      defaultMessage: "Contáctanos para solicitar cualquier información que requieras."
    }),
    translated: true,
  },
  {
    scene: BRANDS_SCENE,
    title: defineMessages({
      id: "HeaderData.brandsTitle",
      defaultMessage: "Conoce más de nuestras marcas y sus tecnologías"
    }),
    description: defineMessages({
      id: "HeaderData.brandsDescription",
      defaultMessage: "Conoce más de las características que tienen las marcas que te ofrecemos."
    }),
    translated: true,
  },
  {
    scene: BLOG_LIST_SCENE,
    title: defineMessages({
      id: "HeaderData.blogListTitle",
      defaultMessage: "Conoce más de nuestras marcas y sus tecnologías"
    }),
    description: defineMessages({
      id: "HeaderData.blogListDescription",
      defaultMessage: "Conoce más de las características que tienen las marcas que te ofrecemos."
    }),
    translated: true,
  },
  {
    scene: ECOSENSE_SCENE,
    title: defineMessages({
      id: "HeaderData.ecosenseTitle",
      defaultMessage: "Orgullosos de presentar nuestro compromiso con el mundo"
    }),
    description: defineMessages({
      id: "HeaderData.ecosenseDescription",
      defaultMessage: "En Spradling estamos comprometidos con el futuro de nuestro planeta. Conoce nuestras estrategias de sostenibilidad."
    }),
    translated: true,
  },
  {
    scene: WE_ARE_SPRADLING_SCENE,
    title: defineMessages({
      id: "HeaderData.wereAreSpradlingTitle"
    }),
    description: defineMessages({
      id: "HeaderData.wereAreSpradlingDescription"
    }),
    translated: true,
  },
  {
    scene: SPRADLING_IN_THE_WORLD_SCENE,
    title: defineMessages({
      id: "HeaderData.spradlingInTheWorldTitle",
      defaultMessage: "Alrededor del mundo"
    }),
    description: defineMessages({
      id: "HeaderData.spradlingInTheWorldDescription",
      defaultMessage: "En Spradling, somos una compañía global con operación comercial y logística en algunas de las principales ciudades del mundo."
    }),
    translated: true,
  },
  {
    scene: WORK_WITH_US_SCENE,
    title: "¿Quieres formar parte del equipo Spradling?",
    description:
      "Estamos en búsqueda de personas apasionadas por lo que hacen. Si eres comprometido, innovas y te gusta el trabajo en equipo; ¡te queremos en Spradling!",
    translated: false,
  },
  {
    scene: PROJECTS_SCENE,
    title: defineMessages({
      id: "HeaderData.projectsTitle",
      defaultMessage: "Inspiración para el mundo con nuestras creaciones"
    }),
    description: defineMessages({
      id: "HeaderData.projectsDescription",
      defaultMessage: "Nuestros diseños han sido la inspiración de grandes creaciones. Hoy en día estamos presentes en más de 75 países alrededor del mundo. ¡Queremos inspirarte!"
    }),
    translated: true,
  },
  {
    scene: DOCUMENT_LIBRARY_SCENE,
    title: defineMessages({
      id: "HeaderData.documentsArchiveTitle",
      defaultMessage: "Consulta la información sobre todos nuestros productos"
    }),
    description: defineMessages({
      id: "HeaderData.documentsArchiveDescription",
      defaultMessage: "Recopilamos la información para que puedas consultar todo lo que debes saber sobre nuestros procesos, productos, su mantenimiento y cuidado, entre otros."
    }),
    translated: true,
  },
  {
    scene: DOCUMENT_LIBRARY_IN_MENU,
    title: defineMessages({
      id: "HeaderData.documentsArchiveTitle",
      defaultMessage: "Consulta la información sobre todos nuestros productos"
    }),
    description: defineMessages({
      id: "HeaderData.documentsArchiveDescription",
      defaultMessage: "Recopilamos la información para que puedas consultar todo lo que debes saber sobre nuestros procesos, productos, su mantenimiento y cuidado, entre otros."
    }),
    translated: true,
  },
  {
    scene: WHY_CHOOSE_US_SCENE,
    title: defineMessages({
      id: "HeaderData.whyChooseUsTitle"
    }),
    description: defineMessages({
      id: "HeaderData.whyChooseUsDescription"
    }),
    translated: true,
  },
  {
    scene: SEARCH_RESULT_SCENE,
    title: defineMessages({
      id: "HeaderData.searchResultTitle",
      defaultMessage: "Coated fabric solutions"
    }),
    description: defineMessages({
      id: "HeaderData.searchResultDescription",
      defaultMessage: "Get to know our high quality coated fabrics for indoor and outdoor spaces with leather-like and textile-like finishes, with unique designs."
    }),
    translated: true,
  },
  {
    scene: CART_SCENE,
    title: defineMessages({
      id: "HeaderData.cartTitle",
      defaultMessage: "Coated fabric solutions"
    }),
    description: defineMessages({
      id: "HeaderData.cartDescription",
      defaultMessage: "Get to know our high quality coated fabrics for indoor and outdoor spaces with leather-like and textile-like finishes, with unique designs."
    }),
    translated: true,
  },
  {
    scene: CART_SUCCESS_SCENE,
    title: defineMessages({
      id: "HeaderData.cartSuccessTitle",
      defaultMessage: "Coated fabric solutions"
    }),
    description: defineMessages({
      id: "HeaderData.cartSuccessDescription",
      defaultMessage: "Get to know our high quality coated fabrics for indoor and outdoor spaces with leather-like and textile-like finishes, with unique designs."
    }),
    translated: true,
  },
  {
    scene: NOT_FOUND_SCENE,
    title: "Error 404",
    description: "",
    translated: false
  },
];
