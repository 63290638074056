import HEADERS_DATA from '../headers_data';
import { HEADERS_DATA_RESOURCES } from './headers_data_rc';
import { HEADERS_DATA_MARINE,  } from './headers_data_sm';

// Headers data by instance in USA case
export const HEADERS_DATA_BY_INSTANCE = {
  sm: HEADERS_DATA,
  rc: HEADERS_DATA_RESOURCES,
  us: HEADERS_DATA
};
