import PropTypes from "prop-types";
import {Query} from "react-apollo";
import singleFlatpageQuery from "modules/FlatpageDetails/query";
import LoadingMessage from "utils/components/LoadingMessage";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import "./styles/FlatpageDetails.scss";

export default function FlatpageDetails({match}) {
  return (
      <div className="flatpage-details">
        <ScrollTopOnMount/>
        <Query
            query={singleFlatpageQuery}
            variables={{
              slug: match.params.flatpageSlug
            }}>
          {({data}) => {

            if (data) {
              const {flatpage} = data
              return (
                  <div className="container pt-4 pb-5 mb-5">
                    <h1 className="pt-5 mb-4">{flatpage.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: flatpage.content}}/>
                  </div>
              );
            }

            return <LoadingMessage/>
          }}
        </Query>
      </div>
  );
}

FlatpageDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      flatpageSlug: PropTypes.string
    })
  }),
};
