import { useQuery } from "react-apollo";
import { singlePatternQuery } from "modules/products/ProductDetailsPage/queries";
import { Redirect } from "react-router";
import { NOT_FOUND_PRODUCT_PAGE } from "routes";
import ProductHeader from "modules/products/ProductDetailsPage/components/ProductHeader";
import {
  getCurrentInstanceId,
  isMARINE,
  isUSA,
  getTranslatedLink,
  isRESOURCES,
} from "services/instances";
import ProductSpecs from "modules/products/ProductDetailsPage/components/ProductSpecs";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import { FormattedMessage } from "react-intl";
import "./styles/ProductDetailsPage.scss";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import { useRouteMatch } from "react-router-dom";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import ProductSidebar from "modules/products/ProductDetailsPage/components/ProductSidebar";
import Skeleton from "react-loading-skeleton";
import RelatedProducts from "modules/products/ProductDetailsPage/components/RelatedProducts";
import {
  ANGLO_SAXON_UNITS,
  INTERNATIONAL_UNITS,
} from "modules/products/ProductDetailsPage/constants/unitSystems";
import { handleProductAltName } from "utils/commons/products";
import SEO from "services/seo/components/SEO";
import { PRODUCTS_LIST_SCENE } from "services/seo/constants/scenes";

export default function ProductDetailsPage() {
  const match = useRouteMatch();
  const { loading, error, data } = useQuery(singlePatternQuery, {
    variables: {
      slug: match.params.productSlug,
      instanceIds: [getCurrentInstanceId()],
      unitSystem:
        isUSA() || isMARINE() || isRESOURCES() ? ANGLO_SAXON_UNITS : INTERNATIONAL_UNITS,
    },
  });
  if (error || (!loading && data && (!data.pattern || !data.pattern.saleable)))
    return <Redirect to={getTranslatedLink(NOT_FOUND_PRODUCT_PAGE, {})} />;

  if (data) {
    const { pattern } = data;

    return (
      // TODO handle the case when pattern is undefined (while loading)
      <PatternContext.Provider value={pattern}>
        <div className="product-details-page">
          <ScrollTopOnMount />
          {/* TODO FUCKING ERROR remove this modal and do it at should be: SEPARATED LOGIC! */}
          {/*{
                        !loading &&
                        <PatternActionsModalContainer
                            pattern={{
                              slug: pattern.slug,
                              name: pattern.name,
                              code: pattern.code,
                              products: pattern.products.edges
                            }}
                            requestType={actionModalRequestType}
                            toggle={this.toggleActionModal}
                            isOpen={actionModalIsOpen}/>
                      }*/}
          <ProductHeader />
          <section className="product-details__info">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-8 col-xl-9 order-md-1 mb-5">
                  <TitleUnderlined hTag={H2_TAG} align="left">
                    {pattern ? (
                      <FormattedMessage
                        id="ProductDetailsPage.specTitle"
                        description="Product details spec title"
                        defaultMessage="{pattern_name} specs"
                        values={{
                          pattern_name: pattern.altName ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleProductAltName(pattern.altName),
                              }}
                            />
                          ) : (
                            pattern.name
                          ),
                          sup: (...chunks) => <sup>{chunks}</sup>,
                        }}
                      />
                    ) : (
                      <Skeleton width={200} />
                    )}
                  </TitleUnderlined>
                  <ProductSpecs />
                </div>
                <div className="col-12 col-md-4 col-xl-3 order-md-0 mb-5">
                  <ProductSidebar />
                </div>
              </div>
            </div>
          </section>
          {pattern && pattern.relatedPatterns.totalCount > 0 && (
            <RelatedProducts />
          )}
        </div>

      <SEO scene={PRODUCTS_LIST_SCENE}/>
      </PatternContext.Provider>
    );
  }
  return null;
}

ProductDetailsPage.propTypes = {};
