import PostsList from "modules/blog/PostsList";
import {FormattedMessage} from "react-intl";
import SEO from "services/seo/components/SEO";
import { BLOG_LIST_SCENE } from "services/seo/constants/scenes";

const PostsListPage = () => (
  <div className="posts-list-page container mt-5">
    <div className="row p-3">
      <h1>
        <FormattedMessage
          id="PostsListPage.title"
          description="Title of the blog list page"
          defaultMessage="Noticias"/>
      </h1>
    </div>
    <PostsList/>
    <SEO scene={BLOG_LIST_SCENE} />
  </div>
)

export default PostsListPage
