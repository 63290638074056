import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { SCENES } from "services/seo/constants/scenes";
import HEADERS_DATA, { HEADER_TITLE_TEMPLATE } from "services/seo/constants/headers_data";
import { injectIntl } from "react-intl";
import { getCurrentLanguageAndInstanceCode, isMARINE, isRESOURCES, isUSA } from "services/instances";
import { HEADERS_DATA_BY_INSTANCE } from "services/seo/constants/headersDataByInstance/headersDataByInstance";

const SEO = ({ intl, scene, data, hotjarInfo, onlyHotjarCode }) => {
  const [lang, instance] = getCurrentLanguageAndInstanceCode();
  const isUsaInstance = isMARINE() || isRESOURCES() || isUSA()
  const headersDataAccordingInstance = isUsaInstance ? HEADERS_DATA_BY_INSTANCE[instance] : HEADERS_DATA

  const headerData = !data
    ? headersDataAccordingInstance.find((meta) => meta.scene === scene)
    : data;

  if (headerData || onlyHotjarCode)
    return (
      <Helmet titleTemplate={HEADER_TITLE_TEMPLATE[instance]}>
        {!onlyHotjarCode && (
          <title>
            {headerData.translated
              ? intl.formatMessage(headerData.title)
              : headerData.title}
          </title>
        )}

        {!onlyHotjarCode && (
          <meta
            name="description"
            content={
              headerData.translated
                ? intl.formatMessage(headerData.description)
                : headerData.description
            }
          />
        )}

        {!onlyHotjarCode && (
          <meta name="keywords" content={headerData.keywords} />
        )}

        {!onlyHotjarCode && (
          <meta
            property="og:title"
            content={
              headerData.translated
                ? intl.formatMessage(headerData.title)
                : headerData.title
            }
          />
        )}

        {!onlyHotjarCode && (
          <meta
            property="og:description"
            content={
              headerData.translated
                ? intl.formatMessage(headerData.description)
                : headerData.description
            }
          />
        )}

        <meta property="og:type" content="website" />

        {hotjarInfo?.hjid && (
          <script>
            {`
              (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = ${JSON.stringify(hotjarInfo)};
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
            `}
          </script>
        )}
      </Helmet>
    );

  return null;
};

SEO.propTypes = {
  scene: PropTypes.oneOf(SCENES),
  intl: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    keywords: PropTypes.string,
  }),
  hotjarInfo: PropTypes.object,
  onlyHotjarCode: PropTypes.bool,
};

export default injectIntl(SEO);
