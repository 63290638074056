import ProjectsListPage from "modules/projects/ProjectsListPage";
import WeAreSpradlingPage from "modules/about/WeAreSpradlingPage/index";
import SingleProjectPage from "modules/projects/SingleProjectPage";
import PostsListPage from "modules/blog/PostsListPage";
import PostDetails from "modules/blog/PostDetails";
import WhyChooseUsPage from "modules/about/WhyChooseUsPage";
import EcosensePage from "modules/about/EcosensePage";
// WARNING: unused WorkWithUsPage
// import EcosensePageOld from "modules/about/EcosensePageOld";
// import WorkWithUsPage from "modules/about/WorkWithUsPage";
import Brands from "modules/about/Brands";
import SingleBrandPage from "modules/about/Brands/components/SingleBrandPage";
import Home from "modules/Home";
import WhereToBuyPage from "modules/stores/WhereToBuyPage/index";
import DocumentLibraryPage from "modules/documents/DocumentLibraryPage";
import SingleMarketPage from "modules/product-lines/SingleMarketPage";
import PQRPage from "modules/contact/PQRPage";
import ContactUsPage from "modules/contact/ContactUsPage";
import SpradlingInTheWorldPage from "modules/about/SpradlingInTheWorldPage";
import ProductListPage from "modules/products/ProductListPage";
import ProductDetailsPage from "modules/products/ProductDetailsPage";
import VacantDetails from "modules/about/VacantDetails";
import RecordsPageContainer from "modules/records/RecordsPage/container";
import DocumentListPage from "modules/documents/DocumentListPage";
import SearchResultsPage from "modules/search/SearchResultsPage";
import FlatpageDetails from "modules/FlatpageDetails";
import ResetPasswordContainer from "modules/auth/scenes/ResetPassword/container";
import ForgotPasswordContainer from "modules/auth/scenes/ForgotPassword/container";
import SamplesCart from "modules/cart/scenes/SamplesCart";
import SamplesCartSuccess from "modules/cart/scenes/SamplesCartSuccess";
import ContactForSamplesPage from "modules/contact/ContactForSamplesPage";
import SingleServicePage from "modules/Home/components/ServicesLines/components/SingleServicePage";
import NotFoundPage from "modules/NotFoundPage";
import NoMatch from "app/components/NoMatch";
import ProseatPage from "modules/proseat/ProseatPage";
import QuickshipPage from "modules/products/QuickshipPage";
import QuickshipPageParamSlug from "modules/products/QuickshipPage/paramSlugPage";
import CompanyPlantsPage from "modules/about/CompanyPlantsPage";

export const HOME_PAGE = "HOME_PAGE";
export const PRODUCTS_LIBRARY = "PRODUCTS_LIBRARY";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const DOCUMENT_LIBRARY_IN_MENU = "DOCUMENT_LIBRARY_IN_MENU";
export const DOCUMENT_LIBRARY = "DOCUMENT_LIBRARY";
export const DOCUMENT_LIST = "DOCUMENT_LIST";
export const ABOUT = "ABOUT";
export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const ABOUT_WE_ARE_SPRADLING = "ABOUT_WE_ARE_SPRADLING";
export const ABOUT_WHY_CHOOSE_US = "ABOUT_WHY_CHOOSE_US";
export const ABOUT_ECOSENSE = "ABOUT_ECOSENSE";
export const ABOUT_SPRADLING_IN_THE_WORLD = "ABOUT_SPRADLING_IN_THE_WORLD";
export const ABOUT_WORK_WITH_US = "ABOUT_WORK_WITH_US";
export const ABOUT_WORK_WITH_US_EXTERNAL = "ABOUT_WORK_WITH_US_EXTERNAL";
export const ABOUT_WORK_WITH_US_VACANT = "ABOUT_WORK_WITH_US_VACANT";
export const ABOUT_BRANDS = "ABOUT_BRANDS";
export const ABOUT_COMPANY_PLANTS = "ABOUT_COMPANY_PLANTS";
export const PROJECTS_LIST = "PROJECTS_LIST";
export const PROJECT_DETAILS = "PROJECT_DETAILS";
export const STORE_LOCATIONS = "STORE_LOCATIONS";
export const BLOG_LIST = "BLOG_LIST";
export const BLOG_DETAILS = "BLOG_DETAILS";
export const FLATPAGE_DETAILS = "FLATPAGE_DETAILS";
export const SERVICES_DETAILS = "SERVICES_DETAILS";
export const MARKET_DETAILS = "MARKET_DETAILS";
export const CONTACT_PQR = "CONTACT_PQR";
export const CONTACT_TALK_TO_US = "CONTACT_TALK_TO_US";
export const RECORDS = "RECORDS";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const SAMPLES_CART = "SAMPLES_CART";
export const SAMPLES_CART_CHECKED_OUT = "SAMPLES_CART_CHECKED_OUT";
export const BRAND_DETAILS = "BRAND_DETAILS";
export const CONTACT_FOR_SAMPLES = "CONTACT_FOR_SAMPLES";
export const PROSEAT_PRODUCT_PAGE = "PROSEAT_PRODUCT_PAGE";
export const QUICKSHIP_PAGE = "QUICKSHIP_PAGE";
export const QUICKSHIP_PAGE_PARAM = "QUICKSHIP_PAGE_PARAM";
export const NOT_FOUND_PRODUCT_PAGE = "NOT_FOUND_PRODUCT_PAGE";
export const NOT_FOUND_PAGE = "NOT_FOUND_PAGE";

export const SPGlobalRoutes = {
  [HOME_PAGE]: {
    es: "",
    en: "",
    pl: "",
    de: "",
    it: "",
    fr: "",
    uk: "",
    ca: "",
    exact: true,
    component: Home,
  },
  [AUTH_FORGOT_PASSWORD]: {
    es: "/usuarios/olvide-mi-contrasena",
    en: "/auth/forgot-password",
    pl: "", // TODO: Missing translation
    de: "", // TODO: Missing translation
    it: "", // TODO: Missing translation
    fr: "", // TODO: Missing translation
    uk: "/auth/forgot-password",
    ca: "/usuarios/olvide-mi-contrasena",
    exact: false,
    component: ForgotPasswordContainer,
  },
  [AUTH_RESET_PASSWORD]: {
    es: "/usuarios/reestablecer-contrasena/:uid/:token",
    en: "/auth/reset-password/:uid/:token",
    pl: "", // TODO: Missing translation
    de: "", // TODO: Missing translation
    it: "", // TODO: Missing translation
    fr: "", // TODO: Missing translation
    uk: "/auth/reset-password/:uid/:token",
    ca: "/usuarios/reestablecer-contrasena/:uid/:token",
    exact: false,
    component: ResetPasswordContainer,
  },
  [ABOUT_WE_ARE_SPRADLING]: {
    es: "/acerca-de/somos-spradling",
    en: "/about-us/we-are-spradling",
    pl: "/o-nas/jesteśmy-spradling", // TODO: Accent typo ?
    de: "/über-uns/wir-sind-spradling",
    it: "/azienda/siamo-spradling",
    fr: "/a-propos/nous-sommes-spradling",
    uk: "/about-us/we-are-spradling",
    ca: "/acerca-de/somos-spradling",
    exact: false,
    component: WeAreSpradlingPage,
  },
  [ABOUT_WHY_CHOOSE_US]: {
    es: "/acerca-de/por-que-escogernos",
    en: "/about-us/why-choose-us",
    pl: "/o-nas/wybierz-tkaniny-powlekane-wybierz-spradling",
    de: "/über-uns/für-beschichtete-textilien-ist-spradling-die-richtige-wahl",
    it: "/azienda/scegli-i-tessuti-rivestiti-scegli-spradling",
    fr: "/a-propos/choisissez-les-tissus-enduits-choisissez-spradling",
    uk: "/about-us/why-choose-us",
    ca: "/acerca-de/por-que-escogernos",
    exact: false,
    component: WhyChooseUsPage,
  },
  [ABOUT_ECOSENSE]: {
    es: "/acerca-de/ecosense",
    en: "/about-us/ecosense",
    pl: "/o-nas/ecosense",
    de: "/über-uns/ecosense",
    it: "/azienda/ecosense",
    fr: "/a-propos/ecosense",
    uk: "/about-us/ecosense",
    ca: "/acerca-de/ecosense",
    exact: false,
    component: EcosensePage,
  },
  //NOTE - This route is deprecated, for this reason, redirect to NoMatch
  [ABOUT_WORK_WITH_US]: {
    es: "/acerca-de/trabaja-con-nosotros",
    en: "/about-us/work-with-us",
    pl: "/o-nas/dolacz-do-nas",
    de: "/über-uns/work-with-us", // TODO: Missing translation
    it: "/azienda/work-with-us", // TODO: Missing translation
    fr: "/a-propos/work-with-us",
    uk: "/about-us/work-with-us",
    ca: "/acerca-de/trabaja-con-nosotros",
    exact: true,
    component: NoMatch,
  },
  [ABOUT_WORK_WITH_US_EXTERNAL]: {
    es: "https://vacantes.spradling.group/",
    en: "https://vacantes.spradling.group/",
    pl: "https://vacantes.spradling.group/",
    de: "https://vacantes.spradling.group/",
    it: "https://vacantes.spradling.group/",
    fr: "https://vacantes.spradling.group/",
    uk: "https://vacantes.spradling.group/",
    ca: "https://vacantes.spradling.group/",
    exact: true,
    component: NoMatch,
  },
  [ABOUT_WORK_WITH_US_VACANT]: {
    es: "/acerca-de/trabaja-con-nosotros/:vacantSlug",
    en: "/about-us/work-with-us/:vacantSlug",
    pl: "/o-nas/dolacz-do-nas/:vacantSlug",
    de: "/über-uns/work-with-us/:vacantSlug", // TODO: Missing translation
    it: "/azienda/work-with-us/:vacantSlug", // TODO: Missing translation
    fr: "/a-propos/work-with-us/:vacantSlug", // TODO: Missing translation
    uk: "/about-us/work-with-us/:vacantSlug",
    ca: "/acerca-de/trabaja-con-nosotros/:vacantSlug",
    exact: false,
    component: VacantDetails,
  },
  [ABOUT_SPRADLING_IN_THE_WORLD]: {
    es: "/acerca-de/spradling-en-el-mundo",
    en: "/about-us/spradling-in-the-world",
    pl: "/o-nas/placowki-na-swiecie",
    de: "/über-uns/internationale-niederlassungen",
    it: "/azienda/spradling-nel-mondo",
    fr: "/a-propos/presence-mondiale",
    uk: "/about-us/spradling-in-the-world",
    ca: "/acerca-de/spradling-en-el-mundo",
    exact: false,
    component: SpradlingInTheWorldPage,
  },
  [ABOUT_BRANDS]: {
    es: "/acerca-de/marcas",
    en: "/about-us/brands",
    pl: "/o-nas/marcas",
    de: "/über-uns/marken",
    it: "/azienda/marche",
    fr: "/a-propos/marques",
    uk: "/about-us/brands",
    ca: "/acerca-de/marcas",
    exact: true,
    component: Brands,
  },
  [ABOUT_COMPANY_PLANTS]: {
    es: "/acerca-de/compania-plantas/:plantSlug",
    en: "/about-us/production-units/:plantSlug",
    // pl: "/o-nas/marcas",
    // de: "/über-uns/marken",
    // it: "/azienda/marche",
    // fr: "/a-propos/marques",
    // uk: "/about-us/brands",
    // ca: "/acerca-de/marcas",
    exact: true,
    component: CompanyPlantsPage,
  },
  [BRAND_DETAILS]: {
    es: "/marcas/:brandSlug",
    en: "/brands/:brandSlug",
    pl: "/marcas/:brandSlug",
    de: "/marken/:brandSlug",
    it: "/marche/:brandSlug",
    fr: "/marques/:brandSlug",
    uk: "/brands/:brandSlug",
    ca: "/marcas/:brandSlug",
    exact: false,
    component: SingleBrandPage,
  },
  // This one is just a dummy url which renders nothing, it's only used for main menu styling purposes
  // I needed a url to be used in the getLink method we've in our multi-lang routes mechanism.
  [ABOUT]: {
    es: "/acerca-de",
    en: "/about-us",
    pl: "/o-nas",
    de: "/über-uns",
    it: "/azienda",
    fr: "/a-propos",
    uk: "/about-us",
    ca: "/acerca-de",
    exact: true,
    component: () => null,
  },
  [PROJECTS_LIST]: {
    es: "/proyectos",
    en: "/projects",
    pl: "/proyects",
    de: "/proyects",
    it: "/progetti",
    fr: "/projets",
    uk: "/projects",
    ca: "/proyectos",
    exact: true,
    component: ProjectsListPage,
  },
  [PROJECT_DETAILS]: {
    es: "/proyectos/:projectSlug",
    en: "/projects/:projectSlug",
    pl: "/proyects/:projectSlug",
    de: "/proyects/:projectSlug",
    it: "/progetti/:projectSlug",
    fr: "/projets/:projectSlug",
    uk: "/projects/:projectSlug",
    ca: "/proyectos/:projectSlug",
    exact: false,
    component: SingleProjectPage,
  },
  [STORE_LOCATIONS]: {
    es: "/donde-comprar",
    en: "/where-to-buy",
    pl: "/where-to-buy", // TODO: Missing translation
    de: "/where-to-buy", // TODO: Missing translation
    it: "/where-to-buy", // TODO: Missing translation
    fr: "/where-to-buy", // TODO: Missing translation
    uk: "/where-to-buy",
    ca: "/donde-comprar",
    exact: true,
    component: WhereToBuyPage,
  },
  [DOCUMENT_LIBRARY_IN_MENU]: {
    es: "/biblioteca-documentos",
    en: "/documents-archive",
    pl: "/dokumenty-archiwum",
    de: "/documents-archive", // TODO: Missing translation
    it: "/documents-archive", // TODO: Missing translation
    fr: "/documents-archives",
    uk: "/documents-archive",
    ca: "/biblioteca-documentos",
    exact: true,
    component: DocumentLibraryPage,
  },
  [DOCUMENT_LIBRARY]: {
    es: "/productos/biblioteca-documentos",
    en: "/products/documents-archive",
    pl: "/kolekcje/dokumenty-archiwum",
    de: "/kollectionen/documents-archive", // TODO: Missing translation
    it: "/collezioni/documents-archive", // TODO: Missing translation
    fr: "/collections/documents-archives",
    uk: "/products/documents-archive",
    ca: "/productos/biblioteca-documentos",
    exact: true,
    component: DocumentLibraryPage,
  },
  [DOCUMENT_LIST]: {
    es: "/productos/biblioteca-documentos/:categorySlug",
    en: "/products/documents-archive/:categorySlug",
    pl: "/kolekcje/dokumenty-archiwum/:categorySlug",
    de: "/kollectionen/documents-archive/:categorySlug", // TODO: Missing translation
    it: "/collezioni/documents-archive/:categorySlug", // TODO: Missing translation
    fr: "/collections/documents-archives/:categorySlug",
    uk: "/products/documents-archive/:categorySlug",
    ca: "/productos/biblioteca-documentos/:categorySlug",
    exact: false,
    component: DocumentListPage,
  },
  [PROSEAT_PRODUCT_PAGE]: {
    en: "/products/documents-archive/proseat",
    exact: true,
    component: ProseatPage,
  },
  [QUICKSHIP_PAGE]: {
    es: "/productos/quickship",
    en: "/products/quickship",
    // pl: "/kolekcje",
    // de: "/kollectionen",
    // it: "/collezioni",
    // fr: "/collections",
    // uk: "/products",
    // ca: "/productos",
    exact: true,
    component: QuickshipPage,
  },
  [QUICKSHIP_PAGE_PARAM]: {
    es: "/productos/quickship/:id",
    en: "/products/quickship/:id",
    // pl: "/kolekcje",
    // de: "/kollectionen",
    // it: "/collezioni",
    // fr: "/collections",
    // uk: "/products",
    // ca: "/productos",
    exact: true,
    component: QuickshipPageParamSlug,
  },
  [PRODUCTS_LIBRARY]: {
    es: "/productos",
    en: "/products",
    pl: "/kolekcje",
    de: "/kollectionen",
    it: "/collezioni",
    fr: "/collections",
    uk: "/products",
    ca: "/productos",
    exact: true,
    component: ProductListPage,
  },
  [PRODUCT_DETAILS]: {
    es: "/productos/:productSlug",
    en: "/products/:productSlug",
    pl: "/kolekcje/:productSlug",
    de: "/kollectionen/:productSlug",
    it: "/collezioni/:productSlug",
    fr: "/collections/:productSlug",
    uk: "/products/:productSlug",
    ca: "/productos/:productSlug",
    exact: true,
    component: ProductDetailsPage,
  },
  [MARKET_DETAILS]: {
    es: "/mercados/:marketSlug",
    en: "/markets/:marketSlug",
    pl: "/kolekcje/:marketSlug", // TODO: Missing translation
    de: "/kollectionen/:marketSlug", // TODO: Missing translation
    it: "/collezioni/:marketSlug", // TODO: Missing translation
    fr: "/collections/:marketSlug", // TODO: Missing translation
    uk: "/markets/:marketSlug",
    ca: "/mercados/:marketSlug",
    exact: false,
    component: SingleMarketPage,
  },
  [BLOG_LIST]: {
    es: "/blog",
    en: "/blog",
    pl: "/nowosci-i-wydarzenia",
    de: "/news-und-events",
    it: "/news-ed-eventi",
    fr: "/nouvelles",
    uk: "/blog",
    ca: "/blog",
    exact: true,
    component: PostsListPage,
  },
  [BLOG_DETAILS]: {
    es: "/blog/:postSlug",
    en: "/blog/:postSlug",
    pl: "/nowosci-i-wydarzenia/:postSlug",
    de: "/news-und-events/:postSlug",
    it: "/news-ed-eventi/:postSlug",
    fr: "/nouvelles/:postSlug",
    uk: "/blog/:postSlug",
    ca: "/blog/:postSlug",
    exact: false,
    component: PostDetails,
  },
  [FLATPAGE_DETAILS]: {
    es: "/paginas/:flatpageSlug",
    en: "/pages/:flatpageSlug",
    pl: "/strony/:flatpageSlug",
    de: "/seiten/:flatpageSlug",
    it: "/pagine/:flatpageSlug",
    fr: "/des-pages/:flatpageSlug",
    uk: "/pages/:flatpageSlug",
    ca: "/paginas/:flatpageSlug",
    exact: false,
    component: FlatpageDetails,
  },
  [SERVICES_DETAILS]: {
    es: "/servicios/:serviceSlug",
    en: "/services/:serviceSlug",
    pl: "/services/:serviceSlug", // TODO: Missing translation
    de: "/services/:serviceSlug", // TODO: Missing translation
    it: "/services/:serviceSlug", // TODO: Missing translation
    fr: "/services/:serviceSlug", // TODO: Missing translation
    uk: "/services/:serviceSlug", // TODO: Missing translation
    ca: "/servicios/:serviceSlug", // TODO: Missing translation
    exact: false,
    component: SingleServicePage,
  },
  [CONTACT_TALK_TO_US]: {
    es: "/contacto",
    en: "/contact",
    pl: "/kontakt",
    de: "/kontakt",
    it: "/contatti",
    fr: "/contact",
    uk: "/contact",
    ca: "/contacto",
    exact: true,
    component: ContactUsPage,
  },
  [CONTACT_PQR]: {
    es: "/contacto/pqrs",
    en: "/contact/pqrs",
    pl: "/kontakt/pqrs", // TODO: Missing translation
    de: "/kontakt/pqrs", // TODO: Missing translation
    it: "/contatti/pqrs", // TODO: Missing translation
    fr: "/contact/pqrs", // TODO: Missing translation
    uk: "/contact/pqrs",
    ca: "/contacto/pqrs",
    exact: false,
    component: PQRPage,
  },
  [CONTACT_FOR_SAMPLES]: {
    es: "/contacto-para-muestras/:patternSlug",
    en: "/contact-for-samples/:patternSlug",
    pl: "/contact-for-samples/:patternSlug", // TODO: Missing translation
    de: "/contact-for-samples/:patternSlug", // TODO: Missing translation
    it: "/contact-for-samples/:patternSlug", // TODO: Missing translation
    fr: "/contact-for-samples/:patternSlug", // TODO: Missing translation
    uk: "/contact-for-samples/:patternSlug",
    ca: "/contacto-para-muestras/:patternSlug",
    exact: true,
    component: ContactForSamplesPage,
  },
  [RECORDS]: {
    es: "/usuarios/solicitudes",
    en: "/users/records",
    pl: "/users/records", // TODO: Missing translation
    de: "/users/records", // TODO: Missing translation
    it: "/users/records", // TODO: Missing translation
    fr: "/users/records", // TODO: Missing translation
    uk: "/users/records",
    ca: "/usuarios/solicitudes",
    exact: false,
    component: RecordsPageContainer,
  },
  [SEARCH_RESULTS]: {
    es: "/resultados-de-busqueda/:text", // TODO: Missing translation
    en: "/search-results/:text", // TODO: Missing translation
    pl: "/search-results/:text", // TODO: Missing translation
    de: "/search-results/:text", // TODO: Missing translation
    it: "/search-results/:text", // TODO: Missing translation
    fr: "/search-results/:text", // TODO: Missing translation
    uk: "/search-results/:text", // TODO: Missing translation
    ca: "/resultados-de-busqueda/:text", // TODO: Missing translation
    exact: false,
    component: SearchResultsPage,
  },
  [SAMPLES_CART]: {
    es: "/carrito", // TODO: Missing translation
    en: "/cart", // TODO: Missing translation
    pl: "/cart", // TODO: Missing translation
    de: "/cart", // TODO: Missing translation
    it: "/cart", // TODO: Missing translation
    fr: "/cart", // TODO: Missing translation
    uk: "/cart", // TODO: Missing translation
    ca: "/carrito", // TODO: Missing translation
    exact: true,
    component: SamplesCart,
  },
  [SAMPLES_CART_CHECKED_OUT]: {
    es: "/carrito/finalizado", // TODO: Missing translation
    en: "/cart/success", // TODO: Missing translation
    pl: "/cart/success", // TODO: Missing translation
    de: "/cart/success", // TODO: Missing translation
    it: "/cart/success", // TODO: Missing translation
    fr: "/cart/success", // TODO: Missing translation
    uk: "/cart/success", // TODO: Missing translation
    ca: "/carrito/finalizado", // TODO: Missing translation
    exact: true,
    component: SamplesCartSuccess,
  },
  [NOT_FOUND_PRODUCT_PAGE]: {
    es: "/page-404",
    en: "/page-404",
    pl: "/page-404",
    de: "/page-404",
    it: "/page-404",
    fr: "/page-404",
    uk: "/page-404",
    ca: "/page-404",
    exact: true,
    component: NotFoundPage,
  },
  [NOT_FOUND_PAGE]: {
    es: "/*",
    en: "/*",
    pl: "/*",
    de: "/*",
    it: "/*",
    fr: "/*",
    uk: "/*",
    ca: "/*",
    exact: false,
    component: NoMatch,
  },
};
